import React from "react";
import { Row, Container, Col, InputGroup, Input, InputGroupText, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import IndexNavbar from "components/navbars/IndexNavbar.js";
import IndexFooter from "components/footers/IndexFooter";
import CubesatCard from "components/cubesat/CubesatCard";
import ApiCaller from "api/ApiCaller";

class LinuxListView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isLoaded: false,
            data: [],
            searchPattern: ""
        }
    }

    _preparePagination() {
        if(this.state.data.length < 15)
            return(<></>);
        return (
            <div style={{ "justifyContent": "center", "display": "flex", "maxWidth": "auto" }}>
                <Pagination size="lx" aria-label="Page navigation example">
                    <PaginationItem>
                        <PaginationLink previous href="#" />
                    </PaginationItem>
                    <PaginationItem>
                        <PaginationLink href="#">
                            1
                        </PaginationLink>
                    </PaginationItem>
                    <PaginationItem>
                        <PaginationLink next href="#" />
                    </PaginationItem>
                </Pagination>
            </div>
        );
    }

    _matchSearchContrain(i) {
        return i.name !== undefined && 
               i.name.toLocaleUpperCase().includes(this.state.searchPattern.toLocaleUpperCase()) ||
               i.size !== undefined && 
               i.size.toLocaleUpperCase().includes(this.state.searchPattern.toLocaleUpperCase()) ||
               i.nation !== undefined && 
               i.nation.toLocaleUpperCase().includes(this.state.searchPattern.toLocaleUpperCase());
    }

    _prepareCubesats() {
        if(!this.state.isLoaded) {
            ApiCaller.getCall(ApiCaller.REST_API_ENDPOINT + "/cubesat/preview", null, (data) => {
                this.setState(
                    {
                        data : data,
                        isLoaded : true
                    }
                );
            });
            return(<></>);
        } else {
            return this.state.data.map((i, e) => {
                if(!this._matchSearchContrain(i))
                    return;

                return (
                    <Col xl="3" sm="6" md="4" xs="12">
                        <CubesatCard data={i} />
                    </Col>
                );
            })
        }
    }

    render() {
        const cubesat = this._prepareCubesats();
        const pagination = this._preparePagination();
        return (<>
            <IndexNavbar />
            <div className="main">
                <Container>
                    <Row style={{ "marginTop": "50px" }}>
                        <Col>
                            <h1>Linux in Space</h1>
                        </Col>
                    </Row>
                    <br />
                    <Row>
                        <Col>
                            <p>This page contains list of satelites, which use Linux in their space mission.</p>
                        </Col>
                    </Row>
                    <div className="section">
                        <Row>
                            <Col xl="12">
                                <InputGroup>
                                    <InputGroupText>
                                        Keyword
                                    </InputGroupText>
                                    <Input placeholder="linux" onChange={(e) => {
                                        this.setState({
                                            searchPattern: e.target.value
                                        });
                                    }}/>
                                </InputGroup>
                            </Col>
                        </Row>

                    </div>
                    <Row>
                        <Col xl="12x">
                            {pagination}
                        </Col>
                    </Row>
                    <Row>
                        {cubesat}
                    </Row>
                    <Row>
                        <Col xl="12x">
                            {pagination}
                        </Col>
                    </Row>
                </Container>
                <IndexFooter />
            </div>
        </>
        );
    }
}

export default LinuxListView;
