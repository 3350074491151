import React from "react";
import LocalText from "../../components/LocalText"

import {
    Container,
    Row,
    Col
} from "reactstrap";

class AboutProject extends React.Component {

    render() {
        return (
            <div className="section" id="about_us">
                <Container>
                    <div className="title">
                        <h2><LocalText textKey="about.project.title" /></h2>
                    </div>
                    <Row>
                        <Col md="12">
                            <LocalText textKey="about.project.text" />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="title">
                                <h3><LocalText textKey="about.project.subtitle1" /></h3>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <LocalText textKey="about.project.text1" />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <div className="title">
                                <h3><LocalText textKey="about.project.subtitle2" /></h3>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <LocalText textKey="about.project.text2" />
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default AboutProject;