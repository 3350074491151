class GlobalConstant {

  static HTTP_PROTOCOL = "https://";
  static DOMAIN_NAME = "linux4space.org";

  //static HTTP_PROTOCOL = "http://";
  //static DOMAIN_NAME = "localhost:8080";

}

export default GlobalConstant;