import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import LocalText from "../../components/LocalText"

import AppConfiguration from "AppConfiguration.json";

import ApiCaller from "api/ApiCaller";

import {
    Container,
    Row,
    Col,
    Button
} from "reactstrap";

class AgendaPlan extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
            agenda : AppConfiguration.AGENDA,
            meetLink: null,
            fileLink: null
        }
    }

    prepareLineElements() {
        if(!this.state.isLoaded) {
            ApiCaller.getCall(ApiCaller.REST_API_ENDPOINT + "/agenda/current", null, (data) => {
                this.setState(
                    {
                        agenda : data,
                        isLoaded : true,
                        meetLink: data.linkToMeet,
                        fileLink: data.linkToFile
                    }
                );
            });
        }
        if(!this.state.agenda) {
            return (<div></div>);
        }

        let elements = this.state.agenda.agendaEventDtos.map((value, index) => {
            const timeColor = (window.innerWidth < 1170)? "#000":"#F1B630";
            const date = new Date(value.eventDate)
            const hour = (date.getHours() > 9)? (date.getHours()): "0" + (date.getHours())
            const minute = (date.getMinutes() > 9)? (date.getMinutes()): "0" + (date.getMinutes())
            const formatedDate = hour + ":" + minute;
            if (index % 2 === 0) {
                return (
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#F1B630', color: timeColor }}
                        contentArrowStyle={{ borderRight: '7px solid  #F1B630' }}
                        date={formatedDate}
                        iconStyle={{ background: '#F1B630', color: '#fff' }}
                    >
                        <h3 className="vertical-timeline-element-title">{value.eventName}</h3>

                        <p>
                            {value.eventDescription}
                        </p>
                    </VerticalTimelineElement>
                );
            } else {
                return (
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#FFF', color: timeColor }}
                        contentArrowStyle={{ borderRight: '7px solid  #F1B630' }}
                        date={formatedDate}
                        iconStyle={{ background: '#FFF', color: '#F1B630' }}
                    >
                        <h3 className="vertical-timeline-element-title">{value.eventName}</h3>

                        <p>
                            {value.eventDescription}
                        </p>
                    </VerticalTimelineElement>
                );
            }
        })

        return elements;
    }

    prepareFileButton() {
        if(this.state.fileLink === null) {
            return <div></div>
        } else {
            return ( 
                <Button
                    style={{fontSize:"11px"}}
                    className="btn-round"
                    color="danger"
                    >
                    <i className="nc-icon nc-paper"></i> Online record
                </Button>
                )
        }
    }

    prepareMeetButton() {
        if(this.state.meetLink === null) {
            return <div></div>
        } else {
            return (         
           <Button
                style={{fontSize:"11px"}}
                className="btn-round float-right"
                color="danger"
                >
                <i className="nc-icon nc-planet"></i> Join on meet
            </Button>
                )
        }
    }


    render() {
        let lineElements = this.prepareLineElements()
        const meetButton = this.prepareMeetButton()
        const fileButton = this.prepareFileButton()

        return (
            <div className="section section-dark" id="agenda">
                <Container>
                    <div className="title">
                        <h2 color="white"><LocalText textKey="agenda.plan" /></h2>
                    </div>
                    <Row>
                        <Col md="12">
                                <hr/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <VerticalTimeline>
                                {lineElements}
                            </VerticalTimeline>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                                <hr/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {fileButton}
                        </Col>

                        <Col>
                            {meetButton}
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }
}

export default AgendaPlan;