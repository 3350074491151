import React from "react";
import LocalText from "../../components/LocalText"

import Slider from "react-slick";

import AppConfiguration from "AppConfiguration.json";

import {
    Container,
    Row,
    Col
} from "reactstrap";
import ApiCaller from "api/ApiCaller";

class Partners extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            partners : AppConfiguration.PARTNERS,
            isLoaded : false
        }
    }

    preparePartners() {
        if(!this.state.isLoaded) {
            ApiCaller.getCall(ApiCaller.REST_API_ENDPOINT + "/partner/all", null, (data) => {
                this.setState(
                    {
                        partners : data,
                        isLoaded : true
                    }
                );
            });
        }

        let partners = this.state.partners.map((value, index) => {
            if(value.partnerUrl) {
                return (
                    <div key={index}>
                        <div style={{height: "100px"}}>
                            <a href={value.partnerUrl}><img
                                style={{
                                    display: "block",
                                    margin: "auto",
                                    objectFit: "contain",
                                }}
                                alt={value.name}
                                src={value.imgUrl}
                                width={100}
                                height={"100%"}
                            />
                            <div>
                                <span style={{color: "black", fontWeight: 400}}>{value.name}</span>
                            </div>
                            </a>
                        </div>
                    </div>
                );
            }  else {
                return (
                    <div key={index}>
                        <div style={{height: "100px"}}>
                            <img
                                style={{
                                    display: "block",
                                    margin: "auto",
                                    objectFit: "contain",
                                }}
                                alt={value.name}
                                src={value.imgUrl}
                                width={100}
                                height={"100%"}
                            />
                            <div>
                                <span style={{color: "black", fontWeight: 400}}>{value.name}</span>
                            </div>
                        </div>
                    </div>
                );
            }
        });

        return partners;
    }

    render() {
        let settings = {
            dots: true,
            infinite: false,
            speed: 500,
            slidesToShow: 4,
            slidesToScroll: 4,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                        initialSlide: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ]
        };

        let partners = this.preparePartners();

        return (
            <div className="section">
                <Container>
                    <div className="title">
                        <h2><LocalText textKey="partners.title" /></h2>
                    </div>
                    <div className="title">
                            <h3><LocalText textKey="partners.title.core.team" /></h3>
                        </div>
                    <Row>
                        <Col md="12">
                            <LocalText textKey="partners.core.team.description" />
                        </Col>
                    </Row>
                    <div className="title">
                            <h3><LocalText textKey="partners.project.participants.title" /></h3>
                    </div>
                    <Row style={{ textAlign: "center", padding: "15px"}}>
                        <div className="container">
                            <Slider {...settings}>
                                {partners}
                            </Slider>
                        </div>
                    </Row>
                </Container>
            </div>
        );
    }
}

export default Partners;