import React from "react";

// reactstrap components
import { Row, Container } from "reactstrap";

function DemoFooter() {
  return (
    <footer className="footer footer-black footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              
            </ul>
          </nav>
          <div className="credits ml-auto" style={{fontSize:"13px"}}>
            <span className="copyright" color="black">
              © {new Date().getFullYear()}, created by Technical University of Liberec 
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
