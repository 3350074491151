/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import LocalText from "components/LocalText";

import imgLinuxBoy from "../../assets/img/linux_boy.png"
import fogLow from "../../assets/img/fog-low.png"

// core components

function IndexHeader() {
  let imgSize = 100;
  if(window.innerWidth < 500) {
    imgSize = 40;
  }

  return (
    <>
      <div
        className="page-header section-dark"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL + '/img/astronomy.jpg'})` 
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <Container>
            <div className="title-brand">
                <div style={{fontSize: "14px"}}>
                  <LocalText isTitle textKey="header.title"/>
                </div>
              <img
                  width={imgSize}
                  alt="..."
                  src={imgLinuxBoy}
                />
              <div className="fog-low">
                <img
                  alt="..."
                  src={fogLow}
                />
              </div>
              <div className="fog-low right">
                <img
                  alt="..."
                  src={fogLow}
                />
              </div>
            </div>
            <h2 className="presentation-subtitle text-center">
              <LocalText textKey="header.description"/>
            </h2>
          </Container>
        </div>
        <div
          className="moving-clouds"
          style={{
            backgroundImage: `url(${process.env.PUBLIC_URL + '/img/clouds.png'})` 
          }}
        />
        <h6 className="category category-absolute">
          <LocalText textKey="header.created"/>
        </h6>
      </div>
    </>
  );
}

export default IndexHeader;
