import React from "react";

import IndexNavbar from "components/navbars/IndexNavbar.js";
import IndexFooter from "components/footers/IndexFooter";
import FormComponent from "./Form";

class FormView extends React.Component {

    render() {
       const formId = window.location.href.split('/')[4]
        return (
            <>
              <IndexNavbar />
              <div className="main" id="form">
                <FormComponent formId={formId}/>
                <IndexFooter/>
              </div>
            </>
          );
    }
}

export default FormView;
