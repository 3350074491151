import React from "react";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import ApiCaller from "api/ApiCaller";
import Alert from 'react-bootstrap/Alert';
import {
    Container,
    Row,
    Col
} from "reactstrap";

class FormComponent extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            data: null,
            loaded: false,
            form : {},
            successSubmit: false,
            alert : [],
            conditions : [],
            disabledSubmitButton: false
        }
        this.form = null;
    }

    onChange(e, data, defaultValue) {
        let tmpForm = this.state.form
        tmpForm[data.name] = e.target.value
        this.setState(
            {
                form : tmpForm
            }
        )
   }

    getComponent(data) {
        switch(data.type) {
            case "EMAIL":
                return (<Form.Control name={data.name} type="email" placeholder={data.placeholder} required={data.required} onChange={(e) => {this.onChange(e, data)}}/>);
            case "TEXT":
                return (<Form.Control name={data.name} type="text" placeholder={data.placeholder} required={data.required} onChange={(e) => {this.onChange(e, data)}}/>);
            case "SELECT":
                return (
                            <Form.Select name={data.name} required={data.required} onChange={(e) => {this.onChange(e, data)}}>
                                <option selected></option>
                                {
                                    data.options.map((item, i)=> {
                                        return (<option value={item.value}>{item.value}</option>);
                                    })
                                }
                            </Form.Select>
                        );
            default:
                return <div></div>
        }
    }

    getData(formId) {
        if(!this.state.loaded) {
            ApiCaller.getCall(ApiCaller.REST_API_ENDPOINT + "/form/" + formId, null, (data) => {
                this.setState(
                    {
                        data : data,
                        conditions: data.conditionDtos,
                        loaded : true
                    }
                );
            });
        } else {
            return this.state.data;
        }

    
    }

    sendData(e) {
        /*if(!e.checkValidity()) {
            return
        }*/ 

        //TODO validation
        e.preventDefault();
        const data = this.state.form;
        const url = this.state.data.submitUrl;
        const method = this.state.data.submitMethod;
        ApiCaller.call(url, method, data, (data) => {
            if(data.submitStatus === "OK") {
                this.setState(
                    {
                        successSubmit : true,
                        alert:[]
                        
                    }
                )
            } else {
                this.setState(
                    {
                        disabledSubmitButton:false,
                        alert:data.errors
                    }
                )
            }
          
        });
        
    }

    isShown(data) {
        let shown = true
        this.state.conditions.forEach(element => {
            if(data.name === element.child && element.formEventType === "HIDE") {
                if(this.state.form[element.base] === element.value) {
                    shown = false
                }
            }
        });
        return shown
    }

    prepareRows(data) {
        const component = data.components;

        return component.map((item, index) => {
            if(this.isShown(item)) {
                const prepareComponent = this.getComponent(item);
                    return (
                    <Row style={{marginTop:"15px"}}>
                        <Col>
                            <Card>
                            <Card.Body>
                                    <Form.Group className="mb-3" controlId={item.id}>
                                        <Form.Label>{item.title + ((item.required)? "*" : "" )}</Form.Label>
                                        <Form.Text >
                                            {item.description}
                                        </Form.Text>
                                        {prepareComponent}
                                    </Form.Group>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>);
            } else {
                return null;
            }
        }
        );
    }

    prepareAlert() {
        if(this.state.alert.length === 0) {
            return (<></>);
        } else {
            return this.state.alert.map((e, index) => {

                return (
                        <Alert key={index} variant={"danger"}>
                            {e}
                        </Alert>);
            });
        }
    }

    render() {
        const formId = this.props.formId;
        const data = this.getData(formId);
        if(!this.state.loaded) {
            return (<></>);
        } 
        const prepareRows = this.prepareRows(data);
        const prepareAlert = this.prepareAlert();
        if(!this.state.successSubmit) {
             return (
                <div className="section" id="form">
                    {prepareAlert}
                    <Container>
                        <div className="title">
                            <h2>{data.formName}</h2>
                        </div>
                        <Form>
                            <Row>
                                <Col md="12">
                                    {data.formDescription}
                                </Col>
                            </Row>
                        {prepareRows}
                            <Row>
                                <Col>
                                    <Button variant="danger" type="submit" style={{"float": "right"}} disabled={this.state.disabledSubmitButton}  onClick={
                                        (e) => {
                                            this.setState({disabledSubmitButton:true}, this.sendData(e))
                                        }
                                    }>
                                            Submit
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Container>
                </div>
            )
        } else {
            return (
                <div className="section" id="form">
                    {prepareAlert}
                    <Container>
                        <div className="title">
                            <h2>{data.formName}</h2>
                        </div>
                        <Row>
                            <Col><h5>Thank you for filling out this form!</h5></Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button variant="danger" href="/">Go back</Button>
                            </Col>
                        </Row>
                    </Container>
                </div>
            )
        }
    }
}

export default FormComponent;