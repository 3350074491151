import React from "react";
import parse from 'html-react-parser';
import AppConfiguration from "AppConfiguration.json";

class LocalText extends React.Component {

    prepareText(lang, key) {
        if (!lang) {
            lang = AppConfiguration.DEFAULT_LANG;
        }

        if (AppConfiguration.TEXTATION) {
            if (AppConfiguration.TEXTATION[key]) {
                if (AppConfiguration.TEXTATION[key][lang]) {
                    if (Array.isArray(AppConfiguration.TEXTATION[key][lang])) {
                        const textationArray = AppConfiguration.TEXTATION[key][lang];
                        return textationArray.map((e, val) => {
                            return (<p color={this.props.color}>{parse(e)}</p>);
                        });
                    } else {
                        return parse(AppConfiguration.TEXTATION[key][lang]);
                    }
                }
            }
        }

        return key;
    }

    render() {
        const lang = this.props.lang
        const textKey = this.props.textKey

        let localizedText = this.prepareText(lang, textKey)
        if (this.props.isTitle) {
            return(
                <h1 className="presentation-title">
                    {localizedText}
                </h1>
            );
        } else {
            return (<div color={this.props.color}>{localizedText}</div>);
        }
    }
}

export default LocalText