
import React from "react";
import { Card, CardBody, CardTitle, CardText, CardSubtitle, Button, CardHeader, Row, Col } from "reactstrap";

class CubesatCard extends React.Component {

    _prepareButton() {
        return (
            <Button style={{ width: "100%" }} href={"/cubesat/" + this.props.data.uuid} color="danger">
                Show more
            </Button>
        );
    }

    render() {
        if(!this.props.data) 
            return(<></>);
        const button = (!this.props.disableButton) ? this._prepareButton() : null;
        const launchedDate = new Date(this.props.data.launched);
        const launched = (launchedDate.getUTCDate()) + "." + (launchedDate.getUTCMonth() + 1) + "." + launchedDate.getFullYear();
        return (
            <Card
                style={{
                    width: '17rem'
                }}
            >
                <CardHeader>
                    {(this.props.data && this.props.data.imgUrl)? (<img width={256} height={170} src={this.props.data.imgUrl} />):""}
                </CardHeader>
                <CardBody>
                    <CardTitle tag="h5">
                        <b>{this.props.data.name}</b>
                    </CardTitle>
                    <CardText>
                        <Row>
                            <Col><b>Size</b></Col>
                            <Col>{this.props.data.size}</Col>
                        </Row>
                        <Row>
                            <Col><b>OS</b></Col>
                            <Col>{(this.props.data.software)? this.props.data.software.join(", "): "--"}</Col>
                        </Row>
                        <Row>
                            <Col><b>Nation</b></Col>
                            <Col>{this.props.data.nation}</Col>
                        </Row>
                        <Row>
                            <Col><b>Launched</b></Col>
                            <Col>{launched}</Col>
                        </Row>
                    </CardText>

                    <CardSubtitle
                        className="mb-2 text-muted"
                        style={{ float: "right" }}
                        tag="h6"
                    >
                    </CardSubtitle>
                    {button}
                </CardBody>
            </Card>);
    }
}

export default CubesatCard;