import React from "react";
import ReactGA from 'react-ga';

// core components
import IndexNavbar from "components/navbars/IndexNavbar.js";
import IndexHeader from "components/headers/IndexHeader.js";

// index section
import AboutProject from "../index-sections/AboutProject";
import ProjectHistory from "../index-sections/ProjectHistory"
import Partners from "../index-sections/Partners"
import Contacts from "../index-sections/Contacts"

import IndexFooter from "components/footers/IndexFooter";
import Cookies from 'js-cookie';
import WelcomeModal from "./Modal";

function Index(props) {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  
  const cookieValue = Cookies.get("cookie-consent")
  if(cookieValue === "true") {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  const params = new URLSearchParams(window.location.search)
  //let showModal = params.has('showModal')
  let showModal = false

  return (
    <>
      <IndexNavbar />
      <IndexHeader />
      {
        (showModal)? <WelcomeModal/>: null
      }
      <div className="main">
        <AboutProject/>
        <ProjectHistory/>
        <Partners/>
        <Contacts/>
        <IndexFooter/>
      </div>
    </>
  );
}

export default Index;
