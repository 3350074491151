import React from "react"
import { atcb_init } from "add-to-calendar-button";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

class WelcomeModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  componentDidMount() {
    
  }

  render() {
    /*
    <AddToCalendar event={ {
                title: 'Linux for Space - Mission begins',
                description: 'Linux4Space session about a new reference distribution for space devices.',
                location: 'Liffey Hall 2 (Level 1), Dublin',
                startTime: '2022-09-16T10:50:00+01:00',
                endTime: '2022-09-16T11:30:00+01:00'
                }}
            />
    */
    return (
      <div>
        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
          <ModalHeader >Thank you for scannig me!</ModalHeader>
          <ModalBody>
            <p>
                Do not miss our session. Please use this button and add our session to your calendar.
            </p>
          </ModalBody>
          <ModalFooter>
            <Calendar/>
            <Button color="danger" onClick={this.toggle}>Close</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

const Calendar = () => {
    React.useEffect(atcb_init, []);
    return (
      <div className="atcb">
        { '{' }
          "name":"Linux for Space - Mission begins",
          "description":"Linux4Space session about a new reference distribution for space devices.",
          "startDate":"2022-09-16",
          "endDate":"2022-09-16",
          "startTime":"10:50",
          "endTime":"11:30",
          "location":"Liffey Hall 2 (Level 1), Dublin",
          "label":"Add to Calendar",
          "options":[
            "Apple",
            "Google",
            "Microsoft365"
          ],
          "timeZone":"Europe/London",
          "iCalFileName":"Reminder-Event"
        { '}' }
      </div>
    );
  }

export default WelcomeModal;