import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

// styles

import "bootstrap/scss/bootstrap.scss";
import "assets/scss/linux4space.scss";

// Cookies and GA
import CookieConsent, { Cookies } from "react-cookie-consent";
import ReactGA from 'react-ga';

// pages
import Index from "views/index/Index";
import Agenda from "views/agenda/Agenda";
import FormView from "views/form/FormView"
import LinuxListView from "views/linux-list/LinuxListView"

import AppConfiguration from "AppConfiguration.json";
import CubesatDescriptionView from "views/cubesat/CubesatDescription";

ReactGA.initialize(AppConfiguration.GA_UNIQUE_ID);

ReactDOM.createRoot(document.getElementById('root')).render(
  <div>
    <CookieConsent
      location="bottom"
      buttonText="Agree"
      cookieName="cookie-consent"
      style={{ background: "#f1b630", color: "#000", fontSize: "20px" }}
      buttonStyle={{ color: "#000", fontSize: "25px" }}
      expires={150}
      onAccept={() => {
         ReactGA.pageview(window.location.pathname + window.location.search);
       }
      }
    >
      This website uses cookies to enhance the user experience and monitoring.{" "}
    </CookieConsent>

    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Index/>} />
        <Route path="/agenda" exact element={<Agenda />}/>
        <Route path="/form/:id" element={<FormView/>}/>
        <Route path="/linux-list" element={<LinuxListView/>}/>
        <Route path="/cubesat/:id" element={<CubesatDescriptionView/>}/>
      </Routes>
    </BrowserRouter>
  </div>
);
