import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import LocalText from "../../components/LocalText"

import AppConfiguration from "AppConfiguration.json";

import ApiCaller from "api/ApiCaller";

import {
    Container,
    Row,
    Col
} from "reactstrap";

class ProjectHistory extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
            milestone : AppConfiguration.MILESTONES
        }
    }

    prepareLineElements() {
        if(!this.state.isLoaded) {
            ApiCaller.getCall(ApiCaller.REST_API_ENDPOINT + "/milestone/all", null, (data) => {
                this.setState(
                    {
                        milestone : data,
                        isLoaded : true
                    }
                );
            });
        }
        let elements = this.state.milestone.map((value, index) => {
            if (index % 2 === 0) {

                return (
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#F1B630', color: '#F1B630' }}
                        contentArrowStyle={{ borderRight: '7px solid  #F1B630' }}
                        date={value.eventDate}
                        iconStyle={{ background: '#F1B630', color: '#fff' }}
                    >
                        <h3 className="vertical-timeline-element-title">{value.eventName}</h3>

                        <p>
                            {value.eventDescription}
                        </p>
                    </VerticalTimelineElement>
                );
            } else {
                return (
                    <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        contentStyle={{ background: '#FFF', color: '#F1B630' }}
                        contentArrowStyle={{ borderRight: '7px solid  #F1B630' }}
                        date={value.eventDate}
                        iconStyle={{ background: '#FFF', color: '#F1B630' }}
                    >
                        <h3 className="vertical-timeline-element-title">{value.eventName}</h3>

                        <p>
                            {value.eventDescription}
                        </p>
                    </VerticalTimelineElement>
                );
            }
        })

        return elements;
    }

    render() {
        let lineElements = this.prepareLineElements()
        return (
            <div className="section section-dark">
                <Container>
                    <div className="title">
                        <h2 color="white"><LocalText textKey="milestone.title" /></h2>
                    </div>
                    <Row>
                        <Col md="12">
                                <hr/>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <VerticalTimeline>
                                {lineElements}
                            </VerticalTimeline>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                                <hr/>
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }
}

export default ProjectHistory;