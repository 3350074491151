import React from "react"
import LocalText from "../../components/LocalText"

import {
    Container,
    Row,
    Col,
    Button
} from "reactstrap";

class Contacts extends React.Component {

    onSubmit(e) {
        e.preventDefault();
    }

    render() {
        return (<div className="section section-dark">
            <Container>
                <div className="title">
                    <h2 color="white"><LocalText textKey="contact.us.title" /></h2>
                </div>
                <Row>
                    <Col md="12">
                        <LocalText textKey="contact.us.description" color="white" />
                    </Col>
                </Row>
                <Row style={{marginTop: "10px"}}>
                    <Col md="4"></Col>
                    <Col md="4">
                        <Button id="registrationFrom"
                            className="btn-round form-button"
                            style={{ width: '300px', "margin": "0 auto " }}
                            color="danger"
                            href="https://linux4space.org/form/b43d61e1-4160-4013-a30e-a576a299dd4f"
                            >
                            <i className="nc-icon nc-laptop"></i> Registration Form
                        </Button>
                    </Col>
                    <Col md="4"></Col>
                </Row>
            </Container>

        </div>)
    }
}

export default Contacts;