import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { slack } from '@fortawesome/free-regular-svg-icons' // <-- import styles to be used
import classnames from "classnames";
import tux_svg from "../../assets/img/tux_astronaut.svg"
// reactstrap components
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";

function IndexNavbar() {
  const navState = (document.location.pathname === "/")?"navbar-transparent":"";
  const [navbarColor, setNavbarColor] = React.useState(navState);
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.location.pathname === "/" &&
        (document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299)
      ) {
        document.getElementById("logo").style.display = "";
        setNavbarColor("");
      } else if (
        document.location.pathname === "/" && (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300)
      ) {
        setNavbarColor("navbar-transparent");
        document.getElementById("logo").style.display = "none";
      } else{
        document.getElementById("logo").style.display = "none";
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar className={classnames("fixed-top", navbarColor)} expand="lg">
      <Container
        style={{"display":"flex"}}
      >
        <div className={("d-flex align-items-center")}>
          <a href="/#">
            <img
              id="logo"
              style={{"display":"none","scale":"60%"}}
              src={tux_svg}
            />
          </a>
        </div>
        <div className="navbar-translate">
          <button
            style={{"margin":"20px"}}
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar> 
            <NavItem>
              <NavLink
                data-placement="bottom"
                style={{"float": "right", "width":"100%"}}
                href="https://gitlab.com/linux4space/"
                title="Gitlab"
              >
               <i className="fa fa-gitlab" />
               <p className="d-lg-none">Gitlab</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                style={{"float": "right", "width":"100%"}}
                href="https://linux4space.slack.com/"
                title="Slack"
              >
               <i className="fa fa-slack" />
               <p className="d-lg-none">Slack</p>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                data-placement="bottom"
                style={{"float": "right", "width":"100%"}}
                href="https://wiki.linux4space.org/"
                title="Wiki"
              >
                <i className="fa fa-wikipedia-w" />
                <p className="d-lg-none">Wiki</p>
              </NavLink>
            </NavItem>
            <NavItem style={{"marginRight":"5px"}}>
              <Button
                className="btn-round"
                color="danger"
                style={{"float": "right", "width":"100%"}}
                href="/#about_us"
              >
                <i className="nc-icon nc-planet"></i> About project
              </Button>
            </NavItem>
            <NavItem style={{"marginRight":"5px"}}>
              <Button
                className="btn-round"
                color="danger"
                style={{"float": "right", "width":"100%"}}
                href="/agenda#agenda"
              >
                <i className="nc-icon nc-spaceship"></i> Resources
              </Button>
            </NavItem>
            <NavItem>
              <Button
                className="btn-round"
                style={{"float": "right", "width":"100%"}}
                color="danger"
                href="/linux-list"
              >
                <i className="nc-icon nc-app"></i> Linux in Space
              </Button>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default IndexNavbar;
