import React from "react";
import ReactGA from 'react-ga';

import IndexHeader from "components/headers/IndexHeader.js";
import IndexNavbar from "components/navbars/IndexNavbar.js";

import AgendaPlan from "views/agenda-section/AgendaPlan";
import AgendaArchiveFiles from "views/agenda-section/AgendaArchiveFiles";

import Contacts from "../index-sections/Contacts"

import IndexFooter from "components/footers/IndexFooter";
import Cookies from 'js-cookie';

function Agenda() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  const cookieValue = Cookies.get("cookie-consent")
  if(cookieValue === "true") {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  return (
    <>
      <IndexNavbar />
      <div className="main">
        <AgendaArchiveFiles/>
        <Contacts/>
        <IndexFooter/>
      </div>
    </>
  );
}

export default Agenda;
