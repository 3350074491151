import React from "react";
import IndexNavbar from "components/navbars/IndexNavbar.js";
import IndexFooter from "components/footers/IndexFooter";
import { Container, Row, Col } from "reactstrap";
import Accordion from 'react-bootstrap/Accordion';
import CubesatCard from "components/cubesat/CubesatCard";
import ApiCaller from "api/ApiCaller";

class CubesatDescriptionView extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
           
        }
    }

    _prepareInformation() {
        if (!this.state.isLoaded) {
            const cubesatId = window.location.href.split('/')[4]
            ApiCaller.getCall(ApiCaller.REST_API_ENDPOINT + "/cubesat/" + cubesatId, null, (data) => {
                this.setState(
                    {
                        data: data,
                        isLoaded: true
                    }
                );
            });
            return (<></>);
        } else {
            const cubesat = this.state.data;
            return (
                <Container>
                    <Row style={{ "marginTop": "50px" }}>
                        <Col>
                            <h1>{cubesat.name}</h1>
                        </Col>
                    </Row>
                    <br></br>
                    <Row>
                        <Col xl="9">
                            <h2>Information</h2>
                            <br />
                            <Row>
                                <Col xl="3"><b>Type</b></Col>
                                <Col xl="3">{(cubesat.type)? cubesat.type:"--"}</Col>
                                <Col xl="3"><b>Operator</b></Col>
                                <Col xl="3">{(cubesat.operator)? cubesat.operator:"--"}</Col>
                            </Row>
                            <br />
                            <Row>
                                <Col xl="3"><b>Launch Vehicle</b></Col>
                                <Col xl="3">{(cubesat.launchVehicle)? cubesat.launchVehicle:"--"}</Col>
                                <Col xl="3"><b>Launch Site</b></Col>
                                <Col xl="3">{(cubesat.launchSite)? cubesat.launchSite:"--"}</Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col xl="6"><b>Mission Controll software</b></Col>
                                <Col>{(cubesat.boardSoftware)? cubesat.boardSoftware.join(", "): "--"}</Col>
                            </Row>
                            <br />
                            
                            <Row>
                            {
                                (!cubesat.cubeSatParts)? "":(
                                    cubesat.cubeSatParts.map((e, i) => {
                                        return (<>
                                        <Col xl="3"><b>{e.name}:</b></Col>
                                            <Col xl="3">
                                                <ul>
                                                    {
                                                        (!e.attributes)? "": e.attributes.map((e, i) => {
                                                                return (<li>e</li>)
                                                            }
                                                        )
                                                    }
                                                </ul>
                                            </Col>
                                        </>);
                                    })
                                )
                            }
                            </Row>
                        </Col>
                        <Col xl="3">
                            <CubesatCard data={cubesat.shortCubeSatInfo} disableButton />
                        </Col>
                    </Row>
                    <Row>
                        <Accordion>
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><b>What Gunter's Space Page says about this cubesat?</b></Accordion.Header>
                                <Accordion.Body>
                                    {cubesat.gutterDescription}
                                    <p><b>Source: </b><i><a href={cubesat.gutterSource}>{cubesat.gutterSource}</a></i></p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header><b>These links might be interesting for you</b></Accordion.Header>
                                <Accordion.Body>
                                    <ul>
                                        {
                                            (!cubesat.links)? "--":cubesat.links.map((e, i)=>{
                                                                    <li><a href={e.link}>{e.title}</a></li>
                                                                })
                                        }
                                    </ul>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Row>
                </Container>
            );
        }
    }

    render() {
        const description = this._prepareInformation();
        return (
            <>
                <IndexNavbar />
                <div className="main">
                    {description}
                    <IndexFooter></IndexFooter>
                </div>
            </>
        );
    }
}

export default CubesatDescriptionView;
