import React from 'react';
import Table from 'react-bootstrap/Table'
import LocalText from "../../components/LocalText"

import AppConfiguration from "AppConfiguration.json";

import ApiCaller from "api/ApiCaller";

import {
    Container,
    Row,
    Col,
    Button
} from "reactstrap";


class AgendaArchiveFiles extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoaded : false,
            files : null
        }
    }

    prepareArchiveFileSection() {
        if(!this.state.isLoaded) {
            ApiCaller.getCall(ApiCaller.REST_API_ENDPOINT + "/archive-file/all", null, (data) => {
                this.setState(
                    {
                        files : data,
                        isLoaded : true
                    }
                );
            });
            return (<div></div>)
        } else {
            if (this.state.files !== null) {
                
                return this.state.files.map((e) => {
                    return (
                            <tr>
                                <td style={{"font-weight":"500"}}><b>{e.name}</b></td>
                                <td style={{"font-weight":"500"}}>{e.description}</td>
                                <td style={{"font-weight":"500"}}><LocalText textKey={'agenda.archive.files.type.' + e.archiveFileType} /></td>
                                <td style={{"font-weight":"500", "text-align": "center"}}>
                                    <a target={"_blank"} href={e.fileUrl} style={{"fontSize":"20px", "color": "black"}}>
                                        <i className="nc-icon nc-share-66"></i>                                
                                    </a>
                                </td>
                            </tr>
                        );
                });

            } else {
                return (<div></div>)
            }
        }
    }

    render() {
        const archiveFileSection = this.prepareArchiveFileSection()
        return (
            <div className="section" id="agenda">
                <Container>
                    <Row>
                    <div className="title">
                        <h2 color="white"><LocalText textKey="agenda.archive.files.title" /></h2>
                    </div>
                    </Row>
                    <Row>
                        <Col>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Description</th>
                                    <th>Type</th>
                                    <th>File</th>
                                </tr>
                            </thead>
                            <tbody>
                                {archiveFileSection}
                            </tbody>
                        </Table>
                        </Col>
                    </Row>
                </Container>

            </div>
        );
    }
}

export default AgendaArchiveFiles;